<template>
  <ion-page>
    <ion-tabs>
      <ion-tab-bar
        slot="bottom"
        translucent
      >
        <ion-tab-button
          tab="planning"
          href="/planning"
        >
          <ion-icon :icon="calendarOutline" />
        </ion-tab-button>

        <ion-tab-button
          tab="registration"
          href="/registratie"
        >
          <ion-icon :icon="timeOutline" />
        </ion-tab-button>

        <ion-tab-button
          tab="profile"
          href="/profiel"
        >
          <ion-icon :icon="personCircleOutline" />
        </ion-tab-button>
      </ion-tab-bar>

      <ion-router-outlet />
    </ion-tabs>
  </ion-page>
</template>

<script>
import {
  IonTabBar, IonTabButton, IonTabs, IonRouterOutlet,
} from '@ionic/vue';
import {
  calendarOutline, timeOutline, personCircleOutline,
} from 'ionicons/icons';
import Auth from '@/services/Auth';

export default {
  components: {
    IonTabs,
    IonTabBar,
    IonTabButton,
    IonRouterOutlet,
  },
  /* c8 ignore next 7 */
  async beforeRouteEnter(to, from, next) {
    if (await Auth.loggedIn()) {
      next();
    } else {
      next({ name: 'login' });
    }
  },
  setup() {
    return {
      calendarOutline,
      timeOutline,
      personCircleOutline,
    };
  },
};
</script>
