import apiClient from '@/apiClient';
import store from '@/store';

export default {
  /**
   * Login user.
   *
   * @param {Object} payload
   *
   * @return {Promise<Object>}
   */
  async login(payload) {
    await apiClient.get('/auth/csrf-cookie');
    await apiClient.post('/auth/login', payload);
    return this.fetchUser();
  },
  /**
   * Logout.
   *
   * @return {Promise}
   */
  logout() {
    return apiClient.post('/auth/logout');
  },
  /**
   * Fetch user.
   *
   * @return {Promise<Object>}
   */
  async fetchUser() {
    const result = await apiClient.get('/auth/me');
    store.auth.user = result.data;
    return store.auth.user;
  },
  /**
   * Logged in check.
   *
   * @return {Promise<Boolean>}
   */
  async loggedIn() {
    if (store.auth.user && store.auth.user.id) {
      return true;
    }

    try {
      await this.fetchUser();
      return true;
    } catch {
      return false;
    }
  },
};
