<template>
  <ion-page>
    <ion-content fixed>
      <ion-grid>
        <ion-row>
          <ion-col>
            <ion-card>
              <ion-card-content>
                <ion-card-title>
                  Inloggen
                </ion-card-title>
                <form
                  @submit.prevent="login"
                >
                  <ion-list lines="none">
                    <ion-item class="ion-no-padding margin-top-sm">
                      <ion-input
                        v-model="email"
                        name="email"
                        type="email"
                        autocomplete="email"
                        placeholder="E-mailadres"
                        aria-label="E-mailadres"
                        required
                      />
                    </ion-item>
                    <ion-item class="ion-no-padding margin-top-sm">
                      <ion-input
                        v-model="password"
                        name="password"
                        type="password"
                        autocomplete="current-password"
                        placeholder="Wachtwoord"
                        aria-label="Wachtwoord"
                        required
                      />
                    </ion-item>
                  </ion-list>
                  <ion-button
                    strong
                    color="success"
                    class="ion-no-margin margin-top-sm ion-margin-bottom"
                    expand="block"
                    router-link="/"
                    router-direction="root"
                    type="submit"
                  >
                    <ion-label slot="start">
                      Inloggen
                    </ion-label>
                  </ion-button>
                </form>
                <a
                  class="forgot-password"
                  href="javascript:void(0)"
                  @click="forgotPassword"
                >
                  Wachtwoord vergeten?
                </a>
              </ion-card-content>
            </ion-card>
          </ion-col>
        </ion-row>
      </ion-grid>
    </ion-content>
  </ion-page>
</template>

<script>
import { defineComponent } from 'vue';
import {
  IonButton, IonInput, alertController, toastController,
} from '@ionic/vue';
import { chevronForwardOutline } from 'ionicons/icons';
import apiClient from '@/apiClient';
import Auth from '@/services/Auth';
import ErrorMixin from '@/mixins/ErrorMixin';

export default defineComponent({
  components: {
    IonButton,
    IonInput,
  },
  mixins: [ErrorMixin],
  setup() {
    return {
      chevronForwardOutline,
    };
  },
  data() {
    return {
      email: '',
      password: '',
      error: null,
    };
  },
  created() {
    this.redirectIfLoggedIn();
  },
  methods: {
    /**
     * Redirect if logged in.
     */
    async redirectIfLoggedIn() {
      if (await Auth.loggedIn()) {
        this.redirect();
      }
    },
    /**
     * Login.
     */
    async login() {
      await this.handleErrors(async () => {
        await Auth.login({
          email: this.email,
          password: this.password,
        });
        this.redirect();
      });
    },
    /**
     * Redirect.
     */
    redirect() {
      this.$router.replace('/');
    },
    /**
     * Forgot password dialog.
     */
    async forgotPassword() {
      const alert = await alertController.create({
        header: 'Wachtwoord vergeten',
        message: 'Voer hier uw emailadres in en we sturen u instructies om uw wachtwoord te resetten.',
        inputs: [
          {
            placeholder: 'Vul uw emailadres in',
            type: 'email',
          },
        ],
        buttons: [
          {
            text: 'Annuleren',
            role: 'cancel',
            cssClass: 'secondary',
          },
          {
            text: 'Versturen',
            handler: (e) => this.sendPasswordMail(e[0]),
          },
        ],
      });
      await alert.present();
    },
    /**
     * Send password mail.
     *
     * @param {String} email
     */
    async sendPasswordMail(email) {
      await this.handleErrors(async () => {
        await apiClient.post('/password/forgot', {
          email,
        });
        const toast = await toastController.create({
          message: 'Wachtwoord reset instructiemail verzonden',
          duration: 2000,
          color: 'success',
        });
        await toast.present();
      });
    },
  },
});
</script>

<style scoped>
ion-content {
  --background: none;

  background-image: url("/public/img/bg.jpg");
  background-position: center;
  background-size: cover;
}

ion-grid {
  display: flex;
  flex-wrap: wrap;
  align-content: center;
  height: 100vh;
  background: rgba(0, 0, 0, 0.5);
}

ion-row {
  width: 100%;
}

ion-router-link {
  text-decoration: underline;
}

ion-item {
  --inner-padding-end: 0;
}

ion-button {
  --border-radius: 5px;
}

.md {
  ion-input {
    padding-left: 8px !important;
  }
}
</style>
