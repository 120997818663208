<template>
  <ion-app>
    <ion-router-outlet />
  </ion-app>
</template>

<script>
import { defineComponent } from 'vue';
import { IonApp, IonRouterOutlet, toastController } from '@ionic/vue';
import EventBus from '@/eventBus';

export default defineComponent({
  components: {
    IonApp,
    IonRouterOutlet,
  },
  created() {
    EventBus.on('sw', async (state) => {
      if (state === 'updated') {
        const toast = await toastController.create({
          message: 'Er is een update beschikbaar.',
          buttons: [
            {
              side: 'end',
              text: 'Vernieuwen',
              /* c8 ignore next 5 */
              handler: async () => {
                const registrations = await navigator.serviceWorker.getRegistrations();
                await Promise.all(registrations.map((r) => r.unregister()));
                window.location.reload(true);
              },
            },
          ],
          color: 'primary',
        });
        await toast.present();
      }
    });
  },
  async mounted() {
    const isiOSSafari = navigator.userAgent.match(/(like Mac OS X|jsdom)/i) !== null;
    if (isiOSSafari && !navigator.standalone) {
      const toast = await toastController.create({
        header: 'Installeer deze app',
        color: 'primary',
        message: 'Voeg toe aan uw beginscherm via <img src="/img/icon-pwa.svg" /> en kies "Zet op beginscherm".',
        buttons: [
          {
            text: 'Sluiten',
            role: 'cancel',
          },
        ],
      });
      await toast.present();
    }
  },
});
</script>
