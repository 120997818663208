import { DateTime } from 'luxon';
import Login from '@/views/Login.vue';
import Tabs from '@/views/Tabs.vue';
import ResetPassword from '@/views/ResetPassword.vue';

export default [
  {
    path: '/login',
    name: 'login',
    component: Login,
  },
  {
    path: '/wachtwoord-resetten/:token/:email',
    name: 'reset-password',
    props: (route) => ({ token: route.params.token, email: route.params.email }),
    component: ResetPassword,
  },
  {
    path: '/',
    component: Tabs,
    redirect: '/planning',
    children: [
      {
        path: 'planning',
        redirect: {
          name: 'planning-week',
          params: { year: DateTime.now().year, week: DateTime.now().weekNumber },
        },
      },
      {
        name: 'planning-week',
        path: '/planning/:year/:week',
        props: (route) => ({
          date: DateTime.fromObject({
            weekYear: +route.params.year,
            weekNumber: +route.params.week,
            weekday: 1,
          }).setLocale('nl'),
        }),
        component: () => import('@/views/Planning.vue'),
      },
      {
        name: 'work-order',
        path: 'planning/:workOrder',
        props: (route) => ({
          id: +route.params.workOrder,
        }),
        component: () => import('@/views/WorkOrder.vue'),
      },
      {
        path: 'registratie',
        redirect: {
          name: 'registration-week',
          params: { year: DateTime.now().year, week: DateTime.now().weekNumber },
        },
      },
      {
        name: 'registration-week',
        path: '/registratie/:year/:week',
        props: (route) => ({
          date: DateTime.fromObject({
            weekYear: +route.params.year,
            weekNumber: +route.params.week,
            weekday: 1,
          }).setLocale('nl'),
        }),
        component: () => import('@/views/Registration.vue'),
      },
      {
        name: 'profile',
        path: 'profiel',
        component: () => import('@/views/Profile.vue'),
      },
    ],
  },
];
