import { createApp } from 'vue';
import '@/registerServiceWorker';
import {
  IonPage,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonThumbnail,
  IonContent,
  IonGrid,
  IonRow,
  IonCol,
  IonButton,
  IonCard,
  IonCardTitle,
  IonCardContent,
  IonList,
  IonItem,
  IonLabel,
  IonText,
  IonIcon,
  IonSpinner,
} from '@ionic/vue';
import ionic from '@/plugins/ionic';
import router from '@/plugins/router';
import apiClient from '@/apiClient';
import store from '@/store';

import App from '@/App.vue';

/* Core CSS required for Ionic components to work properly */
import '@ionic/vue/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/vue/css/normalize.css';
import '@ionic/vue/css/structure.css';
import '@ionic/vue/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/vue/css/padding.css';
import '@ionic/vue/css/float-elements.css';
import '@ionic/vue/css/text-alignment.css';
import '@ionic/vue/css/text-transformation.css';
import '@ionic/vue/css/flex-utils.css';
import '@ionic/vue/css/display.css';

/* Theme variables */
import '@/assets/css/variables.css';
import '@/assets/css/colors.css';

/* Global style */
import '@/assets/css/style.css';

const app = createApp(App)
  .use(ionic, {
    swipeBackEnabled: false,
    innerHTMLTemplatesEnabled: true,
  })
  .use(router);

app.component('IonPage', IonPage);
app.component('IonHeader', IonHeader);
app.component('IonToolbar', IonToolbar);
app.component('IonTitle', IonTitle);
app.component('IonThumbnail', IonThumbnail);
app.component('IonContent', IonContent);
app.component('IonGrid', IonGrid);
app.component('IonRow', IonRow);
app.component('IonCol', IonCol);
app.component('IonButton', IonButton);
app.component('IonCard', IonCard);
app.component('IonCardTitle', IonCardTitle);
app.component('IonCardContent', IonCardContent);
app.component('IonList', IonList);
app.component('IonItem', IonItem);
app.component('IonLabel', IonLabel);
app.component('IonText', IonText);
app.component('IonIcon', IonIcon);
app.component('IonSpinner', IonSpinner);

const globals = app.config.globalProperties;
globals.settings = {
  taxes: [],
};

router.isReady().then(async () => {
  app.mount('#app');

  const taxes = await apiClient.get('taxes');
  globals.settings.taxes = taxes.data;
});

/*
 * Add a response interceptor
 */
apiClient.interceptors.response.use(
  (response) => response,
  (error) => {
    if (
      error.response
      && [401, 419].includes(error.response.status)
    ) {
      store.auth.user = null;
      router.push({ name: 'login' });
    }
    return Promise.reject(error);
  },
);
